import bikiniIcon from "../icons/toys/bikini.png";
import degradebikiniIcon from "../icons/toysDegrade/bikini.png";

import foodIcon from "../icons/toys/comida.png";
import degradFoodIcon from "../icons/toysDegrade/comida.png";

import cosplayIcon from "../icons/toys/cosplay.png";
import degradeCosplayIcon from "../icons/toysDegrade/cosplay.png";

import desnudosIcon from "../icons/toys/desnudos.png";
import degradeDesnudosIcon from "../icons/toysDegrade/desnudos.png";

import fitIcon from "../icons/toys/fit.png";
import degradeFitIcon from "../icons/toysDegrade/fit.png";

import feetIcon from "../icons/toys/lenceria.png";
import degradeFeetIcon from "../icons/toysDegrade/lenceria.png";

import poledancerIcon from "../icons/toys/poledancer.png";
import degradePoleDancerIcon from "../icons/toysDegrade/poledancer.png";

import sadoIcon from "../icons/toys/sado.png";
import degradeSadoIcon from "../icons/toysDegrade/sado.png";

import piesIcon from "../icons/toys/pies.png";
import degradePiesIcon from "../icons/toysDegrade/pies.png";

export const toys = [
  { id: 1, icon: bikiniIcon, degradeIcon: degradebikiniIcon, selected: false },
  { id: 2, icon: foodIcon, degradeIcon: degradFoodIcon, selected: false },
  { id: 3,
    icon: cosplayIcon,
    degradeIcon: degradeCosplayIcon,
    selected: false,
  },
  { id: 4, icon: desnudosIcon, degradeIcon: degradeDesnudosIcon, selected: false },
  { id: 5, icon: fitIcon, degradeIcon: degradeFitIcon, selected: false },
  { id: 6, icon: feetIcon, degradeIcon: degradeFeetIcon, selected: false },
  { id: 7, icon: poledancerIcon, degradeIcon: degradePoleDancerIcon, selected: false },
  { id: 8, icon: sadoIcon, degradeIcon: degradeSadoIcon, selected: false },
  { id: 9, icon: piesIcon, degradeIcon: degradePiesIcon, selected: false },
];

export const packToys = [
  { id: 0, description: "Bikini", selected: false },
  { id: 1, description: "Comida", selected: false },
  { id: 2, description: "Cosplay", selected: false },
  { id: 3, description: "Desnudos", selected: false },
  { id: 4, description: "Fit", selected: false },
  { id: 5, description: "Lenceria", selected: false },
  { id: 6, description: "PoleDancer", selected: false },
  { id: 7, description: "Sado", selected: false },
  { id: 8, description: "Pies", selected: false },
];

export const hotmyVersion = "15.24.01"
