import { Autoplay, Navigation, Pagination, Scrollbar, A11y } from "swiper";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { Swiper, SwiperSlide } from "swiper/react";
import ShareIcon from "@material-ui/icons/Share";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { useEffect, useState } from "react";
import { setHeadersWithOutToken } from "../../libs/fetch";
import { MiniSpinner } from "../../commonComponents/miniSpinner/miniSpinner";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "./swipperContent.css";
import videoIconBlack from "../../icons/a_news/content/video_B.svg";
import photoIconBlack from "../../icons/a_news/content/image_B.svg";
import packIconBlack from "../../icons/a_news/subscriptions/gift_B.svg";
import logoHotmyNuevo from "../../../src/icons/a_news/general/logo100px.png";

export const SwipperContent = (props) => {
  const [hotmerProfilePromotionLoading, setHotmerProfilePromotionLoading] =
    useState(false);

  const [hotmerProfilePromotion, setHotmerProfilePromotion] = useState([]);

  const [hotmerProfileValid, setHotmerProfileValid] = useState(false);

  const getHotmerProfilePromotions = async (ds_nick_name) => {
    setHotmerProfilePromotionLoading(false);
    setHotmerProfileValid(false);
    const url = `${process.env.REACT_APP_INITIAL_V2}/hotmers/promotion/${ds_nick_name}`;
    try {
      setHotmerProfilePromotionLoading(true);
      const res = await fetch(url, {
        headers: setHeadersWithOutToken(),
      });
      if (res.status === 200) {
        const response = await res.json();
        console.log("RESPONSE ACTIVITY: ", response.data);
        setHotmerProfilePromotion(response.data);
        setHotmerProfileValid(true);
      } else if (res.status === 204) {
        setHotmerProfilePromotion([]);
      }
    } catch (error) {
      console.log(error);
    }
    setHotmerProfilePromotionLoading(false);
    // setHotmerProfilePromotion(false);
  };

  useEffect(() => {
    getHotmerProfilePromotions(props.creatorName);
  }, []);

  const { cerrar } = props;

  const shareProfile = (e, name) => {
    e.preventDefault();
    if (!navigator.share) {
      alert("Navegador no soporta la Web Share API");
      return;
    }
    navigator
      .share({
        url: process.env.PUBLIC_URL + "/" + name,
      })
      .then(() => console.log("Successful share"))
      .catch((error) => console.log("Error sharing", error));
  };

  return (
    <div className="container">
      {hotmerProfilePromotionLoading ? (
        <div className="container-miniSpinner">
          <MiniSpinner />
        </div>
      ) : (
        <>
          {hotmerProfileValid ? (
            <Swiper
              className="swiper"
              id="swipper-promos"
              modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
              nextbutton=".swiper-button-next"
              prevbutton=".swiper-button-prev"
              centeredSlides={true}
              spaceBetween={30}
              slidesPerView={1}
              navigation
              loop={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: true,
              }}
              // onSlideChange={(swiper) => console.log(swiper)}
            >
              <div className="swiper-wrapper">
                <div className="swiper-button-prev"></div>
                {hotmerProfilePromotion?.map((hotmers) => (
                  <>
                    <SwiperSlide
                      className="swiper-slide"
                      key={hotmers.id_hotmer}
                    >
                      <img
                        id={hotmers.id_hotmer}
                        src={hotmers.ds_profile_photo_path}
                        alt="img"
                        className="img-fluid"
                        height="100%"
                        width="100%"
                      />
                      <div className="swiper-button-next"></div>
                      <div className="container-components">
                        <div>
                          {/* <div className='grid-hotmer-name-icon'> */}
                          <div className="grid-hotmer-name-icon-share">
                            <ShareIcon
                              className="shareicon"
                              onClick={(e) =>
                                shareProfile(e, hotmers.ds_display_name)
                              }
                            ></ShareIcon>
                            <Typography
                              className="capitalize text--gradient"
                              style={{
                                // margin: "0.5rem 0 0.1rem 0",
                                fontWeight: "bold",
                                fontSize: "1.4rem",
                              }}
                            >
                              {hotmers.ds_display_name}
                            </Typography>
                            <Typography
                              style={{
                                // margin: "0.5rem 0",
                                fontSize: "0.6rem",
                                letterSpacing: "1.5px",
                                fontWeight: "bold",
                              }}
                            >
                              {hotmers.ds_type_of_profile}
                            </Typography>
                          </div>
                          {/* GRILLA DE 6 REGIONES  */}
                          <div className="container-components">
                            <div className="grid-content">
                              <div className="">
                                <img
                                  className="grid-icons"
                                  alt="FotoIcon"
                                  src={photoIconBlack}
                                ></img>
                              </div>
                              <div>.</div>
                              <div className="">
                                <img
                                  className="grid-icons"
                                  alt="VideoIcon"
                                  src={videoIconBlack}
                                ></img>
                              </div>
                              <div>.</div>
                              <div className="">
                                <img
                                  className="grid-icons"
                                  alt="PackIcon"
                                  src={packIconBlack}
                                ></img>
                              </div>
                              <Typography style={{ fontSize: "0.8rem" }}>
                                Fotos
                              </Typography>
                              <span> </span>
                              <Typography style={{ fontSize: "0.8rem" }}>
                                Videos
                              </Typography>
                              <span> </span>
                              <Typography style={{ fontSize: "0.8rem" }}>
                                Packs
                              </Typography>
                            </div>
                          </div>
                          <div>
                            <div
                              className="mask--button--freeAccess"
                              onClick={cerrar}
                            >
                              <Typography
                                style={{
                                  margin: "0.5rem 0",
                                  color: "white",
                                  fontSize: "1.2rem",
                                  letterSpacing: "1px",
                                }}
                              >
                                ACCEDER GRATIS
                              </Typography>
                            </div>
                          </div>
                          <div></div>
                          <div>
                            <Typography
                              style={{
                                margin: "0.5rem 0",
                                fontSize: "0.6rem",
                                letterSpacing: "1.5px",
                                fontWeight: "bold",
                              }}
                            >
                              CONTENIDO EXCLUSIVO
                            </Typography>
                          </div>
                          <Divider />
                          <div style={{ margin: "1rem 0 1rem 0" }}>
                            <img src={logoHotmyNuevo} />
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  </>
                ))}
              </div>
              {/* <!-- If we need navigation buttons --> */}
            </Swiper>
          ) : (
            // <div className="container">
            <Card
              style={{
                maxWidth: 400,
                margin: "auto",
                mt: 5,
                p: 2,
                boxShadow: 3,
                borderRadius: 2,
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CardContent>
                <Typography variant="h5" component="div" gutterBottom>
                  Usuario inválido
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                  Lo sentimos, pero estas intentando entrar a una ruta invalida
                  o no tienes acceso autorizado. Por favor, verifica tus
                  credenciales.
                </Typography>
              </CardContent>
              <CardActions
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="mask--button--freeAccess" onClick={cerrar}>
                  <Typography
                    style={{
                      margin: "0.5rem 0",
                      color: "white",
                      fontSize: "1.2rem",
                      letterSpacing: "1px",
                    }}
                  >
                    CERRAR
                  </Typography>
                </div>
              </CardActions>
            </Card>
          )}
        </>
      )}
    </div>
  );
};
